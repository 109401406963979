import { useState, useEffect, useMemo, useCallback } from 'react';
import { DataGrid, GridColDef, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import { Box, InputLabel, MenuItem, FormControl, Select, Button, Typography } from '@mui/material';
import { getDomains } from 'src/util/database';
import 'dayjs/locale/pt-br';
import { downloadExcel } from 'src/util/xlsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { GridSortDirection, GridSortModel } from '@mui/x-data-grid';
import { query, where, getDocs } from 'firebase/firestore';
import { usePaginatedData } from '../../hooks/paginationHooks';
import SessionsTotalsTable from '../utils/SessionsTotalsTable';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import { buildCollectionRef } from 'src/firebase/firestore';
import { getSessionsBackupsCol } from 'flyid-core/dist/Util/database';
import { CustomSessionData } from 'flyid-core/dist/Database/Models/HardwareTracking/SessionData';
import useStateReducer from 'flyid-ui-components/dist/hooks/useStateReducer';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';
import CustomDatePicker from '../utils/CustomDatePicker';
import PermissionWarning from '../widgets/PermissionWarning';

type SessionDataWithId = CustomSessionData<Date, { id: string }>;

export type PageState = {
  data: any[];
  total: number;
  page: number;
  pageSize: number;
};

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Session',
    width: 200
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    width: 200,
    valueFormatter: (param) => new Date(param.value).toLocaleString()
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    width: 200,
    valueFormatter: (param) => new Date(param.value).toLocaleString()
  },
  {
    field: 'total',
    headerName: 'Total Readings',
    width: 200
  },
  {
    field: 'empty',
    headerName: 'Empty',
    width: 200
  },
  {
    field: 'invalid',
    headerName: 'Invalid Readings',
    width: 200
  },
  {
    field: 'valid',
    headerName: 'Valid Readings',
    width: 200
  },
  {
    field: 'flightDuration',
    headerName: 'Flight Duration',
    width: 200,
    valueFormatter: (param) => `${param.value} s`
  },
  {
    field: 'avgReadingTime',
    headerName: 'Average Reading Time',
    width: 200,
    valueFormatter: (param) => `${param.value} /s`
  }
];

const SessionHistory: React.FC = () => {
  //User data and claims
  const { userData, claims } = useAppSelector((s) => ({
    userData: selectProfile(s),
    claims: selectProfileClaims(s)
  }));

  const [companies, setCompanies] = useState<string[]>([]);
  const [domains, setDomains] = useState<string[]>([]);
  const [orderByColumn, setOrderByColumn] = useState<string | null>(null);
  const [columnOrder, setColumnOrder] = useState<GridSortDirection>(null);

  //Select Buttons
  const [companySelected, setCompanySelected] = useStoredState('analyticsCompanySelect', '');
  const handleCompanyChange = (event) => {
    setCompanySelected(event.target.value as string);
  };

  const [domainSelected, setDomainSelected] = useStoredState('sessionHistoryDomainSelect', '');
  const handleDomainChange = (event) => {
    setDomainSelected(event.target.value as string);
  };

  //DatePicker Related
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const handleDateChange = (value: [Date, Date] | null) => setDateRange(value);

  //Datagrid Related
  const [pageState, setPageState] = useStateReducer<PageState>({
    data: [],
    total: 0,
    page: 0,
    pageSize: 10
  });

  const resetPageState = () => {
    setPageState({
      data: [],
      total: 0,
      page: 0,
      pageSize: 10
    });
  };

  const onSortModelChange = (model: GridSortModel) => {
    const columnModel = model[0];
    const columnField = columnModel?.field;
    if (columnField === 'startTime' || columnField === 'endTime') {
      setOrderByColumn(columnField);
      setColumnOrder(columnModel.sort);
      setPageState({ page: 0 });
    } else {
      setOrderByColumn(null);
      setColumnOrder(null);
    }
  };

  //Datagrid Toolbar
  const CustomToolbar = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <GridToolbar />
        <GridToolbarContainer sx={{ paddingLeft: 0 }}>
          <Button
            onClick={() => downloadExcel(pageState.data)}
            size="small"
            sx={{ padding: 0, marginRight: 1 }}
          >
            <SaveAltIcon sx={{ fontSize: '16.81px', marginRight: 1 }} />
            Export Page to Excel
          </Button>
          <Button onClick={handleExport} size="small" sx={{ padding: 0 }}>
            <SaveAltIcon sx={{ fontSize: '16.81px', marginRight: 1 }} />
            Export All Data to Excel
          </Button>
        </GridToolbarContainer>
      </Box>
    );
  };

  //Exporting All Data to Excel
  const handleExport = async () => {
    if (dateRange !== null) {
      const datalist: {}[] = [];
      dateRange[1].setHours(23, 59, 59);

      const _query = query(
        buildCollectionRef(getSessionsBackupsCol(companySelected, domainSelected)),
        where('startTime', '>=', dateRange[0]),
        where('startTime', '<=', dateRange[1]),
        where('action', '==', 'CREATE')
      );

      await getDocs(_query).then((sessionData) => {
        sessionData.docs.map((doc) => datalist.push({ ...doc.data(), id: doc.id }));
      });

      downloadExcel(datalist);
    }
  };

  // Query
  const sessionDataQuery = useMemo(() => {
    resetPageState();

    if (!companySelected || !domainSelected || dateRange === null) return null;
    dateRange[1].setHours(23, 59, 59);

    return query(
      buildCollectionRef(getSessionsBackupsCol(companySelected, domainSelected)),
      where('startTime', '>=', dateRange[0]),
      where('startTime', '<=', dateRange[1]),
      where('action', '==', 'CREATE')
    );
  }, [dateRange, companySelected, domainSelected]);

  const onPageChange = useCallback((page: number) => setPageState({ page }), [setPageState]);

  //Pagination Hook
  const useColumnOrder = orderByColumn === 'startTime';
  const rawSessionsData = usePaginatedData(
    sessionDataQuery,
    pageState.page,
    pageState.pageSize,
    useColumnOrder ? orderByColumn : null,
    useColumnOrder ? columnOrder : null,
    onPageChange
  );

  useEffect(() => {
    if (claims)
      claims.keyUser
        ? setCompanies(claims.company as unknown as string[])
        : setCompanies([claims.company]);
  }, [claims]);

  useEffect(() => {
    setDomains([]);
    if (companySelected) {
      getDomains(companySelected).then((_domains) => {
        setDomains(Array.from(new Set(Object.values(_domains).map((d) => d.id))));
      });
    }
  }, [companySelected]);

  useEffect(() => {
    const _sessionData = rawSessionsData.data;
    if (!_sessionData) return;

    const sessionsData = _sessionData.map((sessionSnap) => {
      const sessionData = sessionSnap.data();

      const data: SessionDataWithId = {
        ...sessionData,
        id: String(sessionData.startTime)
      };

      return data;
    });

    setPageState({
      data: sessionsData,
      total: rawSessionsData.count
    });
  }, [rawSessionsData.data, rawSessionsData.count]);

  //Layout
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {claims?.extraFeatures.usageAnalytics ? (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', width: 900, marginBottom: 2 }}>
              {/* Company Select Button */}
              <FormControl sx={{ marginRight: 2, width: 200, marginTop: 1 }} fullWidth>
                <InputLabel id="company-select-label">Companhia</InputLabel>
                <Select
                  labelId="company-select-label"
                  id="company-select"
                  value={companySelected}
                  label="Companhia"
                  onChange={handleCompanyChange}
                >
                  <MenuItem value={''}>
                    <em>Nenhum</em>
                  </MenuItem>
                  {companies.map((company) => {
                    return (
                      <MenuItem value={company} key={company}>
                        {company}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {/* Domain Select Button */}
              <FormControl sx={{ width: 200, marginTop: 1 }}>
                <InputLabel id="domain-select-label">Domínio</InputLabel>
                <Select
                  labelId="domain-select-label"
                  id="domain-select"
                  value={domainSelected}
                  label="Domínio"
                  onChange={handleDomainChange}
                >
                  <MenuItem value={''}>
                    <em>Nenhum</em>
                  </MenuItem>
                  {domains.map((domain) => {
                    return (
                      <MenuItem value={domain} key={domain}>
                        {domain}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {companySelected && domainSelected ? (
                <CustomDatePicker onChange={handleDateChange} />
              ) : null}
            </Box>
          </Box>
          {/* Sessions Datagrid */}
          {companySelected && domainSelected && dateRange ? (
            <>
              <Box sx={{ height: '70vh', width: '70vw', marginBottom: 5 }}>
                <DataGrid
                  rows={pageState.data}
                  rowCount={pageState.total}
                  pageSize={pageState.pageSize}
                  loading={rawSessionsData.isLoading}
                  page={pageState.page}
                  onPageChange={(newPage) => setPageState({ page: newPage })}
                  getRowId={(row: any) => row.startTime + ''}
                  columns={columns}
                  rowsPerPageOptions={[10, 25, 50]}
                  onPageSizeChange={(newPageSize) => setPageState({ pageSize: newPageSize })}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  components={{
                    Toolbar: CustomToolbar
                  }}
                  onSortModelChange={onSortModelChange}
                />
              </Box>
              {/*Totals and Means Table*/}
              <Box sx={{ marginBottom: 10 }}>
                <Typography sx={{ fontSize: 28 }}>Totals and Means</Typography>
                <Typography sx={{ fontSize: 16, marginBottom: 2 }}>
                  Loaded itens: {pageState.data.length}
                </Typography>
                <SessionsTotalsTable
                  data={rawSessionsData.totalsAndMeans}
                  isLoading={rawSessionsData.isLoading}
                />
              </Box>
            </>
          ) : null}
        </Box>
      ) : (
        <PermissionWarning />
      )}
    </Box>
  );
};

export default SessionHistory;
