"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatteryDataConverter = void 0;
const time_1 = require("../../../Util/time");
exports.BatteryDataConverter = {
    fromFirestore: (snap) => {
        const data = snap.data();
        return Object.assign(Object.assign({}, data), { operationDate: (0, time_1.parseDateFromTimestamp)(data.operationDate), startTime: (0, time_1.parseDateFromTimestamp)(data.startTime), flightStartTime: (0, time_1.parseDateFromTimestamp)(data.flightStartTime), endTime: (0, time_1.parseDateFromTimestamp)(data.endTime) });
    },
    toFirestore: (data) => {
        return Object.assign(Object.assign({}, data), { operationDate: data.operationDate instanceof Date ? data.operationDate : new Date(data.operationDate), startTime: data.startTime instanceof Date ? data.startTime : new Date(data.startTime), flightStartTime: data.flightStartTime instanceof Date
                ? data.flightStartTime
                : new Date(data.flightStartTime), endTime: data.endTime instanceof Date ? data.endTime : new Date(data.endTime) });
    }
};
