import { Switch, Route } from 'react-router-dom';
import Login from './components/layouts/Login';
import ForgotPassword from './components/layouts/ForgotPassword';
import BatteryHistory from './components/dashboard/BatteryHistory';
import SessionHistory from './components/dashboard/SessionHistory';
import ProtectedRoute from './components/utils/ProtectedRoute';
import UsageCounter from './components/dashboard/UsageCounter';
import FlightLogCharts from './components/dashboard/FlightLogCharts';
import Home from './components/layouts/Home';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgotpassword" component={ForgotPassword} />
      <ProtectedRoute exact path="/batteryhistory" component={BatteryHistory} />
      <ProtectedRoute exact path="/sessionhistory" component={SessionHistory} />
      <ProtectedRoute exact path="/usagecounter" component={UsageCounter} />
      <ProtectedRoute exact path="/flightlogcharts" component={FlightLogCharts} />
      <ProtectedRoute exact={false} path="/" component={Home} />
    </Switch>
  );
};

export default Routes;
