"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersistenceSettingsConverter = exports.InterfaceSettingsConverter = exports.isValidSessionPushSettings = exports.isSapRfcCallSettings = exports.isWebServicePushSettings = exports.DefaultPushSettings = exports.DefaultWebServicePushSettings = exports.DefaultSessionParserSettings = void 0;
const firestore_1 = require("../../Util/firestore");
const helpers_1 = require("../../Util/helpers");
exports.DefaultSessionParserSettings = {
    flattenOutput: false,
    noBaseFields: false,
    useInputOrder: false,
    useCustomTransformer: false
};
exports.DefaultWebServicePushSettings = {
    pushUrl: '',
    deleteOnPush: false,
    isTriggerActive: false
};
exports.DefaultPushSettings = Object.assign(Object.assign({}, exports.DefaultWebServicePushSettings), exports.DefaultSessionParserSettings);
const isWebServicePushSettings = (sett) => {
    const eps = sett;
    return !!eps.pushUrl && (0, helpers_1.isNonNulli)(eps.deleteOnPush);
};
exports.isWebServicePushSettings = isWebServicePushSettings;
const isSapRfcCallSettings = (sett) => {
    const srcps = sett;
    return !!srcps.connParams && !!srcps.targetRFC;
};
exports.isSapRfcCallSettings = isSapRfcCallSettings;
const isValidSessionPushSettings = (sett) => (0, exports.isWebServicePushSettings)(sett) || (0, exports.isSapRfcCallSettings)(sett);
exports.isValidSessionPushSettings = isValidSessionPushSettings;
exports.InterfaceSettingsConverter = (0, firestore_1.DirectConverter)();
exports.PersistenceSettingsConverter = (0, firestore_1.DirectConverter)();
