import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { getFirestore, Firestore, connectFirestoreEmulator } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import getFirebaseConfig from './config';
import environment from 'src/environment';

// Initialize firebase instance
const config = getFirebaseConfig(environment)
const firebaseApp: FirebaseApp = initializeApp(config);
const auth: Auth = getAuth(firebaseApp);
const firestore: Firestore = getFirestore(firebaseApp);
const storage: FirebaseStorage = getStorage(firebaseApp);
const flightLogsBucket: FirebaseStorage = getStorage(firebaseApp, config.flightLogsBucket);

if (environment.usingEmulators) {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

// Get firebase references for store
const initFirebase = () => ({ firebaseApp, auth, firestore, storage });

export { initFirebase, firebaseApp, auth, firestore, storage, flightLogsBucket };
