import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import { getCompanies, getCount } from '../../../util/database';
import { useRemoteData } from 'src/hooks/chartHooks';
import LoadingCircle from 'src/components/widgets/LoadingCircle';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import ChartFilter from 'src/components/utils/ChartFilter';
import BadRequest from '../../widgets/BadRequest';
import ChartDomainFilter from 'src/components/utils/ChartDomainFilter';
import CustomDatePicker from 'src/components/utils/CustomDatePicker';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectProfile, selectProfileClaims } from 'src/redux/selectors/userSelectors';

const MultiDomainChart: React.FC = () => {
  // User data and claims
  const { userData, claims } = useAppSelector((s) => ({
    userData: selectProfile(s),
    claims: selectProfileClaims(s)
  }));

  const [companies, setCompanies] = useState<string[]>([]);
  const [domains, setDomains] = useState<string[]>([]);

  // Select Button
  const [companySelected, setCompanySelected] = useStoredState<string>(
    'multiDomainChartSelect',
    ''
  );

  const handleSelectChange = (event) => {
    setCompanySelected(event.target.value);
  };

  // Filter Related
  const [filteredValues, setFilteredValues] = useState<string[]>([]);

  const handleFilterChange = (values: string[]) => {
    const getValues = new Set<string>();
    values.forEach((value: string) => {
      getValues.add(value);
    });
    setFilteredValues(Array.from(getValues));
  };

  const [domainsFiltered, setDomainsFiltered] = useState<string[]>([]);

  const handleDomainFilterChange = (values: string[]) => {
    const getValues = new Set<string>();
    values.forEach((value: string) => {
      getValues.add(value);
    });
    setDomainsFiltered(Array.from(getValues));
  };

  //Date Picker Relared
  const [dateRange, setDateRange] = useState<[Date, Date] | null>(null);

  const handleDateChange = (value: [Date, Date] | null) => {
    if (value) setDateRange(value);
  };

  //Fetching Data
  const fetchCount = useCallback(async () => {
    if (companySelected) {
      if (dateRange) {
        const count = await getCount([companySelected], dateRange).then((count) => {
          return [{ ...count }];
        });
        return count;
      } else {
        const count = await getCount([companySelected]).then((count) => {
          return [{ ...count }];
        });
        return count;
      }
    }
    return [];
  }, [companySelected, dateRange]);

  useEffect(() => {
    if (claims)
      claims.keyUser
        ? setCompanies(claims.company as unknown as string[])
        : setCompanies([claims.company]);
  }, [claims]);

  //Chart Config
  const series: any[] = [
    filteredValues.includes('sessionCREATE')
      ? null
      : { dataKey: 'sessionCREATE', label: 'Session Create' },
    filteredValues.includes('sessionDELETE')
      ? null
      : { dataKey: 'sessionDELETE', label: 'Session Delete' },
    filteredValues.includes('sessionUPDATE')
      ? null
      : { dataKey: 'sessionUPDATE', label: 'Session Update' },
    filteredValues.includes('taskCREATE') ? null : { dataKey: 'taskCREATE', label: 'Task Create' },
    filteredValues.includes('taskDELETE') ? null : { dataKey: 'taskDELETE', label: 'Task Delete' },
    filteredValues.includes('taskUPDATE') ? null : { dataKey: 'taskUPDATE', label: 'Task Update' }
  ].filter(Boolean);

  const handleData = useCallback(
    (data: Record<string, Record<string, Record<string, number>>>[]) => {
      const countDataList = new Set<{}>();
      const domainsList: string[] = [];

      data.map((_data) => {
        Object.keys(_data[companySelected]).forEach((domain: string) => {
          const domainData = _data[companySelected][domain];

          if (!domainsFiltered.includes(domain)) {
            countDataList.add({
              sessionCREATE: filteredValues.includes('sessionCREATE')
                ? null
                : domainData['sessionCREATE'],
              sessionDELETE: filteredValues.includes('sessionDELETE')
                ? null
                : domainData['sessionDELETE'],
              sessionUPDATE: filteredValues.includes('sessionUPDATE')
                ? null
                : domainData['sessionUPDATE'],
              taskCREATE: filteredValues.includes('taskCREATE') ? null : domainData['taskCREATE'],
              taskDELETE: filteredValues.includes('taskDELETE') ? null : domainData['taskDELETE'],
              taskUPDATE: filteredValues.includes('taskUPDATE') ? null : domainData['taskUPDATE'],
              domain: domain
            });
          }

          domainsList.push(domain);
        });
      });

      setDomains(domainsList);

      const filteredData = Array.from(countDataList).filter((element) => {
        return element !== null && element !== undefined;
      });

      return filteredData;
    },
    [domainsFiltered, filteredValues, companySelected]
  );

  const [data, loading, error] = useRemoteData(fetchCount, handleData);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {/* Company Select Button */}
        <FormControl sx={{ width: 200, marginTop: 1 }} fullWidth>
          <InputLabel id="company-select-label">Company</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            value={companySelected}
            label="Company"
            onChange={handleSelectChange}
          >
            <MenuItem value={''}>
              <em>Nenhum</em>
            </MenuItem>
            {companies.map((company) => {
              return (
                <MenuItem value={company} key={company}>
                  {company}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <CustomDatePicker onChange={handleDateChange} />
        {companySelected && data && domains.length ? (
          <>
            <ChartFilter onChange={handleFilterChange} />
            <ChartDomainFilter options={domains} onChange={handleDomainFilterChange} />
          </>
        ) : null}
      </Box>

      {!companySelected ? null : loading ? (
        <Box sx={{ marginTop: 10 }}>
          <LoadingCircle />
        </Box>
      ) : error ? (
        <Box sx={{ marginTop: 5 }}>
          <BadRequest text={error.message} />
        </Box>
      ) : data?.length ? (
        <Box sx={{ display: 'flex', justifyContent: 'right', width: 1000 }}>
          <BarChart
            width={700}
            height={400}
            dataset={data}
            yAxis={[{ scaleType: 'band', dataKey: 'domain' }]}
            series={series}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'right' }
              }
            }}
            sx={{
              '--ChartsLegend-itemMarkSize': '8px',
              '--ChartsLegend-labelSpacing': '5px',
              '--ChartsLegend-rootSpacing': '9px'
            }}
            layout="horizontal"
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default MultiDomainChart;
